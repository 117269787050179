import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { Layout } from "../components/common";
import { MetaData } from "../components/common/meta";

import { loadStripe } from "@stripe/stripe-js";
import { getACF } from "../utils";
import { stripe as stripe_key, api } from "../constants";
import { set } from "lodash";

const chunkArrayInGroups = (arr, size) => {
    var myArray = [];
    for (var i = 0; i < arr.length; i += size) {
        myArray.push(arr.slice(i, i + size));
    }
    return myArray;
};

const copy = (interval, interval_count) => {
    // if(interval === 'month' && interval_count === 3) return 'quarter';
    return interval_count > 1
        ? interval_count + " " + interval + "s"
        : interval;
};

const Body = ({ page }) => {
    const [rows, setRows] = useState([[null, null]]);
    const [loading, setLoading] = useState(false);
    const [quantities, setQuantities] = useState([]);
    const [canBuy, setCanBuy] = useState([]);

    const getCheckoutSession = async (price, i, quantity) => {
        setLoading(i);
        const response = await fetch(api + "/payment/create-checkout-session", {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                price: price.id,
                mode: price.recurring ? "subscription" : "payment",
                quantity: quantity || 1,
            }),
        });
        const { id } = await response.json();
        await window.stripe.redirectToCheckout({ sessionId: id });
        setLoading(false);
    };

    const init = async () => {
        [stripe] = await Promise.all([
            loadStripe(stripe_key).then((s) => (window.stripe = s)),
            fetch(api + "/payment/prices").then(async (response) => {
                let { data } = await response.json();
                data = data
                    .sort((a, b) => a.unit_amount - b.unit_amount)
                    .filter((p) => p.active);
                const all = [
                    ...data.filter((d) => !d.recurring),
                    ...data.filter((d) => d.recurring),
                ];
                const rows = chunkArrayInGroups(all, 2);
                setRows(rows);
                setQuantities(new Array(all.length).fill(1));
                setCanBuy(new Array(all.length).fill(true));
            }),
        ]);
    };

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        console.log(
            "canBuy.map((_, i) => quantities[i] >= 1)",
            canBuy.map((_, i) => quantities[i] >= 1)
        );
        setCanBuy(quantities.map((_, i) => quantities[i] >= 1));
    }, [quantities]);

    return (
        <div class="bg-white">
            <div class="max-w-screen-xl mx-auto">
                <div class="sm:flex sm:flex-col sm:align-center">
                    <section
                        className="content-body load-external-scripts"
                        dangerouslySetInnerHTML={{ __html: page.html }}
                    ></section>
                </div>
                {rows.map((r) => (
                    <div
                        class="mt-6 mb-16 space-y-4 sm:mt-2 sm:space-y-0 sm:grid sm:gap-6 sm-min-w-lg sm:mx-auto sm:max-w-none xl:mx-0 sm:grid-cols-2"
                        style={{ marginTop: "2rem" }}
                    >
                        {r.map((p, i) => (
                            <div class="border border-gray-200 rounded-lg shadow-md divide-y divide-gray-200">
                                <>
                                    <div class="p-6 flex flex-col justify-between">
                                        <div>
                                            {!p ? (
                                                <div class="animate-pulse flex space-x-4">
                                                    <div class="flex-1 space-y-4 py-1">
                                                        <div class="h-4 bg-gray-400 rounded w-3/4"></div>
                                                        <div class="space-y-2">
                                                            <div class="h-4 bg-gray-400 rounded"></div>
                                                            <div class="h-4 bg-gray-400 rounded w-5/6"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    <h2
                                                        class="text-2xl leading-8 font-medium text-gray-900"
                                                        style={{ marginTop: 0 }}
                                                    >
                                                        {p.recurring &&
                                                            "Subscribe to "}{" "}
                                                        {p
                                                            ? p.product.name
                                                            : ""}
                                                    </h2>
                                                </div>
                                            )}
                                            <div>
                                                <span class="text-lg text-gray-500">
                                                    {p &&
                                                        p.product &&
                                                        p.product.description}
                                                </span>
                                            </div>
                                            <div className="mt-2">
                                                <span class="text-4xl leading-10 font-extrabold text-gray-900">
                                                    $
                                                    {p
                                                        ? p.unit_amount / 100
                                                        : ""}
                                                </span>
                                                <span className="text-md">
                                                    {p && p.recurring
                                                        ? " / " +
                                                          copy(
                                                              p.recurring
                                                                  .interval,
                                                              p.recurring
                                                                  .interval_count
                                                          )
                                                        : ""}
                                                </span>
                                            </div>
                                        </div>

                                        {p && (
                                            <div>
                                                <label
                                                    for="price"
                                                    class="block text-xl font-semibold leading-5 text-gray-700 mt-4"
                                                >
                                                    Quantity
                                                </label>
                                                <div class="mt-1 relative rounded-md shadow-sm">
                                                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                        <span class="text-gray-500 text-xl sm:leading-5"></span>
                                                    </div>
                                                    <input
                                                        value={quantities[i]}
                                                        onChange={(e) => {
                                                            const q = [
                                                                ...quantities,
                                                            ];
                                                            const {
                                                                value,
                                                            } = e.target;
                                                            const v = parseInt(
                                                                value
                                                            );
                                                            if (
                                                                !v ||
                                                                (v >= 1 &&
                                                                    Math.round(
                                                                        v
                                                                    ) === v)
                                                            ) {
                                                                q[i] = v;
                                                                setQuantities(
                                                                    q
                                                                );
                                                            }
                                                        }}
                                                        id="price"
                                                        type="number"
                                                        class="form-input block w-full pl-7 pr-12 text-xl sm:leading-5"
                                                        placeholder="1"
                                                        aria-describedby="price-currency"
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        <button
                                            type="button"
                                            class={`mt-6 relative inline-flex items-center px-4 py-2 ${
                                                !canBuy[i]
                                                    ? "bg-gray-700"
                                                    : "bg-gray-800"
                                            } border border-gray-800 rounded-md py-2 text-sm leading-5 font-semibold text-white hover:bg-gray-700 focus:outline-none focus:shadow-outline-gray transition duration-150 ease-in-out h-12 text-xl`}
                                            disabled={loading || !canBuy[i]}
                                            onClick={() => {
                                                if (!canBuy[i]) return;
                                                getCheckoutSession(
                                                    p,
                                                    i + 1,
                                                    quantities[i]
                                                );
                                            }}
                                        >
                                            <div className="flex flex-row items-center justify-center w-full">
                                                {loading === i + 1 && (
                                                    <svg
                                                        style={{
                                                            position:
                                                                "absolute",
                                                            left: "1rem",
                                                        }}
                                                        class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <circle
                                                            class="opacity-25"
                                                            cx="12"
                                                            cy="12"
                                                            r="10"
                                                            stroke="currentColor"
                                                            stroke-width="4"
                                                        ></circle>
                                                        <path
                                                            class="opacity-75"
                                                            fill="currentColor"
                                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                        ></path>
                                                    </svg>
                                                )}
                                                <span>Buy</span>
                                            </div>
                                        </button>
                                    </div>
                                    <div class="pt-6 pb-8 px-6">
                                        {p &&
                                            p.product &&
                                            p.product.metadata &&
                                            p.product.metadata
                                                .whats_included && (
                                                <>
                                                    <h3 class="text-xs leading-4 font-medium text-gray-900 tracking-wide uppercase">
                                                        What's included
                                                    </h3>
                                                    <ul class="mt-6 space-y-4">
                                                        {p.product.metadata.whats_included
                                                            .split("-")
                                                            .map((i) => {
                                                                if (!i)
                                                                    return null;
                                                                return (
                                                                    <li class="flex space-x-3">
                                                                        {/* <!-- Heroicon name: check --> */}
                                                                        <svg
                                                                            class="flex-shrink-0 h-5 w-5 text-green-500"
                                                                            aria-hidden="true"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 20 20"
                                                                            fill="currentColor"
                                                                        >
                                                                            <path
                                                                                fill-rule="evenodd"
                                                                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                                clip-rule="evenodd"
                                                                            />
                                                                        </svg>
                                                                        <span class="text-sm leading-5 text-gray-500">
                                                                            {i}
                                                                        </span>
                                                                    </li>
                                                                );
                                                            })}
                                                    </ul>
                                                </>
                                            )}
                                    </div>
                                </>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ({ data, location }) => {
    const page = data.ghostPage;

    return (
        <>
            <MetaData data={data} location={location} type="website" />
            <Helmet>
                <style type="text/css">{`${page.codeinjection_styles}`}</style>
            </Helmet>
            <Layout>
                <div
                    class="flex items-center w-full justify-center"
                    style={{
                        position: "relative",
                        height: "calc(50vh - 6rem)",
                        top: 0,
                        left: 0,
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        overflow: "hidden",
                    }}
                >
                    <h1
                        className="content-title"
                        style={{ color: "white", zIndex: 10, marginBottom: 0 }}
                    >
                        {page.title}
                    </h1>
                    <div
                        id="bg"
                        style={{
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundImage: `url('${page.feature_image}')`,
                            position: "absolute",
                            height: "50vh",
                            top: 0,
                            left: 0,
                            width: "100%",
                        }}
                    />
                </div>
                <div className="container">
                    <article className="content">
                        <Body page={page} />
                    </article>
                </div>
            </Layout>
        </>
    );
};

export const preOrderQuery = graphql`
    query($slug: String!) {
        ghostPage(slug: { eq: $slug }) {
            ...GhostPageFields
        }
    }
`;
